<template>
  <div>
    <h1 class="headline--md text-bold text-uppercase mb-16">New Crowdfunding plan</h1>

    <fund-form @create-plan="onCreatePlan($event)" @cancel="onCancelUpdate" />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { CREATE_PLAN_SUCCESS, CONFIRM_BEFORE_LEAVE } from '@/store/support/message'
const FundForm = () => import('@/components/FundForm')

export default {
  components: {
    FundForm
  },

  methods: {
    ...mapActions('crowdfunding', ['addCrowdfundingPlan']),

    ...mapMutations('alert', ['STORE_ALERT']),

    initDataRequest(plan) {
      return {
        ...plan,
        smart_contract_id: 1,
        program_id: this.$route.params.programId
      }
    },

    onCreatePlan(planData) {
      this.addCrowdfundingPlan(this.initDataRequest(planData)).then(() => {
        this.$router.push({ name: 'CrowdfundingPlans' })
        this['STORE_ALERT']({ type: 'success', content: CREATE_PLAN_SUCCESS, timeOut: 1600 })
      })
    },

    onCancelUpdate() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'CrowdfundingPlans') {
      next()
    } else {
      const answer = window.confirm(CONFIRM_BEFORE_LEAVE)
      if (answer) {
        next()
      } else {
        next(false)
      }
    }
  }
}
</script>
